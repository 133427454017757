import React, { useState } from "react";

const FileUpload = ({ label, _disabled, _selectedFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const supportedFileExtensions = [".xlsx"]; // Add or modify supported extensions

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (supportedFileExtensions.includes(`.${fileExtension}`)) {
        setSelectedFile(file);
        _selectedFile({ file: null, error: null });
      } else {
        _selectedFile({
          file: null,
          error:
            "Unsupported file type. Please choose a valid Excel file. Only '.xlsx' files are supported."
        });
        // Optionally, you can reset the input value to clear the selection
        event.target.value = null;
      }
    }
  };

  const handleUpload = () => {
    // Perform the file upload logic here
    if (!selectedFile) {
      _selectedFile({ file: null, error: "No file selected" });
    } else if (selectedFile) {
      setSelectedFile(null);
      _selectedFile({ file: selectedFile, error: null });
    }
  };

  return (
    <div>
      <input disabled={_disabled} type="file" onChange={handleFileChange} />
      <button
        disabled={(_disabled && !selectedFile) || !selectedFile}
        onClick={handleUpload}
      >
        {label}
      </button>
    </div>
  );
};

export default FileUpload;
