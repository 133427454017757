import React, { useState, useEffect } from "react";
import axios from "axios";
import { ExportTableToCSV } from "export-table-to-csv";
import { headers } from "../Utils/firebase";
import { _handleUpload } from "../Utils/upload";
import FileUpload from "./fileUpload";
import config from "../config";

const SyncQtyStockToFront = ({ token }) => {
  // constants

  const limitBulkUpdateCalls = 100;
  const limitBulkProductDetailsCalls = 500;
  const _limitBulkProductDetailsCalls = 5000;
  const [limitBulkChunkSize, setLimitBulkChunkSize] = useState(
    3000 //limitBulkProductDetailsCalls
  );

  // state variables

  const [inputValue, setInputValue] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [callCounter, setCallCounter] = useState(0);
  const [chunkedArrays, setChunkedArrays] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [selectedHighButtonIndex, setSelectedHighButtonIndex] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [loadingMsg, setLoadingMsg] = useState(null);
  const [filterBy, setFilterBy] = useState("require_update"); //require_update, existing, new
  // eslint-disable-next-line no-unused-vars
  const [apiCallCount, setApiCallCount] = useState(0);

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  // useEffects
  useEffect(() => {
    if (selectedArray?.length > 0) {
      getDeltaUpdatedProductQuantities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArray]);

  // helper functions

  const _transformArray = (originalArray, chunkSize) => {
    const transformedArray = [];
    for (let i = 0; i < originalArray.length; i += chunkSize) {
      const chunk = originalArray.slice(i, i + chunkSize);
      transformedArray.push(chunk);
    }
    return transformedArray;
  };

  const _getLatestUpdatedProductQuantitiesFromUpload = async () => {
    const _fetchedData = uploadedData;
    if (_fetchedData.length > 0) {
      const _chunkedArrays = _transformArray(_fetchedData, limitBulkChunkSize);
      setChunkedArrays(_chunkedArrays);
      setSelectedArray(_chunkedArrays[0]);
      setSelectedButtonIndex(0);
      setLoading(false);
    }
  };

  const _fetchPraxisProducts = async page => {
    await delay(200);
    const response = await axios.get(
      `${config.API_URL}/api/v1/praxis/products/availibilities/${inputValue}/${page}`,
      headers(token)
    );
    setApiCallCount(prevCount => {
      const newCount = prevCount + 1;
      const precentageCahed = newCount / (response.data?.data?.totalPages || 1);
      const msg = `<div>${Math.round(
        precentageCahed * 100
      )} % Chunk(s) cached</div>
      <div> ~ ${newCount * 500} parxis variants fetched.<div>
      <div>Comparing fetched data chunks with existing stock ...</div>`;
      setLoadingMsg(msg);
      return newCount;
    });
    return response;
  };

  const _getUpdatedProductQuantities = async () => {
    let allProducts = [];
    const initialResponse = await _fetchPraxisProducts(1);
    if (
      initialResponse.data?.data &&
      initialResponse.data?.data?.totalPages > 0
    ) {
      allProducts = [...initialResponse.data.data.data];
      const totalPages = initialResponse.data.data.totalPages;
      const pagePromises = [];
      for (let page = 2; page <= totalPages; page++) {
        pagePromises.push(await _fetchPraxisProducts(page));
      }
      const pageResponses = await Promise.all(pagePromises);
      pageResponses.forEach(response => {
        allProducts = allProducts.concat(response.data.data.data);
      });
    }
    return allProducts;
  };

  const _getLatestUpdatedProductQuantities = async () => {
    const productListResponse = await _getUpdatedProductQuantities();
    if (productListResponse?.length > 0) {
      const _chunkedArrays = _transformArray(
        productListResponse,
        limitBulkChunkSize
      );
      setChunkedArrays(_chunkedArrays);
      setSelectedArray(_chunkedArrays[0]);
      setSelectedButtonIndex(0);
      setApiCallCount(0);
      setLoading(false);
    } else {
      setError("No Praxis data found");
      setLoading(false);
    }
  };

  const _getProductsByCursor = async (first, after) => {
    setCallCounter(prevCounter => prevCounter + 1);
    const response = await axios.post(
      `${config.API_URL}/api/v1/shopify/products`,
      {
        first,
        after
      },
      headers(token)
    );
    return response.data.data;
  };

  const _getAllProducts = async (afterCursor = null) => {
    const { products, pageInfo } = await _getProductsByCursor(250, afterCursor);
    if (pageInfo && pageInfo.hasNextPage) {
      const remainingProducts = await _getAllProducts(pageInfo.endCursor);
      return [...products, ...remainingProducts];
    } else {
      return products;
    }
  };

  const _bulkUpdate = async blukLimit => {
    const variantUpdates = blukLimit.map(p => ({
      inventoryItemId: p.inventoryId,
      availableDelta: parseInt(p.delta, 10)
    }));
    const response = await axios.post(
      `${config.API_URL}/api/v1/shopify/products/bulk/quantity`,
      {
        inventoryItemAdjustments: variantUpdates
      },
      headers(token)
    );
    const invetoryLevels =
      response?.data?.data?.data?.inventoryBulkAdjustQuantityAtLocation
        ?.inventoryLevels;
    return invetoryLevels;
  };

  const _bulkUpdateSuccess = (invetoryLevels, products) => {
    const updatedProduct = invetoryLevels?.reduce((acc, updatedVariant) => {
      const updatedInventoryItemId = updatedVariant.item.id;
      const updatedProducts = _updateInventoryQuantityLocaly(
        acc,
        updatedInventoryItemId
      );
      return updatedProducts;
    }, products);

    setProducts(updatedProduct);
  };

  const _getPendingUpdates = updatedListData => {
    return updatedListData.length > 0
      ? updatedListData.filter(p => !!p.delta)
      : [];
  };

  const _updateInventoryQuantityLocaly = (data, selectedInventoryItemId) => {
    return data.map(entry => {
      entry.shopify.forEach(variant => {
        if (variant.inventoryItemId === selectedInventoryItemId) {
          variant.inventoryQuantity = entry.product.availableQty;
        }
      });
      return entry;
    });
  };

  const _updateProductsLocaly = inventoryItemId => {
    const _products = _updateInventoryQuantityLocaly(products, inventoryItemId);
    setProducts(_products);
  };

  const _selectedFile = ({ file, error }) => {
    if (file) {
      setError(false);
      setLoadingMsg(null);
      setLoading(true);
      setProducts([]);
      setSelectedArray([]);
      setChunkedArrays([]);
      setSelectedButtonIndex(null);
      setSelectedHighButtonIndex(null);
      _handleUpload(file, setError, setLoading, setUploadedData);
    } else {
      setError(error);
    }
  };

  // reducing fetched data to be used in rendering

  const createProductData = () => {
    const formattedProducts = [];
    if (products?.length > 0) {
      products.forEach(p => {
        if (p) {
          const product = p.product;
          const edges = p.shopify;
          const pItem = {
            praxisProductTitle: product.name,
            praxisQuantity: product.availableQty,
            praxisBarcode: product.barcode,
            status: "New"
          };
          if (edges?.length) {
            const edge = edges[0];
            const item = {
              ...pItem,
              variantId: edge.variantId.split("/").pop(),
              barcode: edge.barcode,
              sku: edge.sku,
              productId: edge.productId.split("/").pop(),
              title: edge.displayName,
              price: edge.price,
              inventoryId: edge.inventoryItemId,
              quantity: edge.inventoryQuantity,
              delta:
                parseFloat(product.availableQty || 0) -
                parseFloat(edge.inventoryQuantity || 0),
              status:
                parseFloat(edge.inventoryQuantity || 0) ===
                parseFloat(product.availableQty || 0)
                  ? "Ok"
                  : "Differ"
            };
            formattedProducts.push(item);
          } else formattedProducts.push(pItem);
        }
      });
    }
    return formattedProducts;
  };

  // buttons handle functions starts here

  const fetchShopifyProducts = async () => {
    setLoadingMsg(null);
    setLoading(true);
    setError(null);
    setProducts([]);
    setUploadedData([]);
    setSelectedArray([]);
    setChunkedArrays([]);
    setSelectedButtonIndex(null);
    setSelectedHighButtonIndex(null);
    const _products = await _getAllProducts();
    setAllProducts(_products);
    setCallCounter(0);
    if (_products?.length) {
      _getLatestUpdatedProductQuantities();
    }
  };

  const fetchShopifyProductsWithUploadedData = async () => {
    setLoadingMsg(null);
    setLoading(true);
    setError(null);
    setProducts([]);
    setSelectedArray([]);
    setChunkedArrays([]);
    setSelectedButtonIndex(null);
    setSelectedHighButtonIndex(null);
    const _products = await _getAllProducts();
    setAllProducts(_products);
    setCallCounter(0);
    if (_products?.length) {
      _getLatestUpdatedProductQuantitiesFromUpload();
    }
  };

  const getDeltaUpdatedProductQuantities = () => {
    try {
      setLoading(true);
      setError(null);
      const detailedProductList = [];
      selectedArray.forEach(product => {
        const productDetailResponse = allProducts.filter(
          _product => _product.barcode === product.barcode
        );
        detailedProductList.push({
          product,
          shopify: productDetailResponse
        });
      });
      setProducts(detailedProductList);
      setLoading(false);
    } catch (error) {
      setError("Fetching Delta quantity product list");
      setLoading(false);
    }
  };

  const bulkUpdate = async () => {
    setLoadingMsg(null);
    setLoading(true);
    setError(null);

    const listData = createProductData();
    const DifferProducts = _getPendingUpdates(listData);
    const blukLimit =
      DifferProducts.length < limitBulkUpdateCalls
        ? DifferProducts
        : DifferProducts.slice(0, limitBulkUpdateCalls);

    const invetoryLevels = await _bulkUpdate(blukLimit);

    _bulkUpdateSuccess(invetoryLevels, products);

    const isPendingUpdates = DifferProducts.length > 0;
    if (!isPendingUpdates) {
      setSelectedHighButtonIndex(selectedButtonIndex);
    }
    setLoading(false);
  };

  const updateProductQuantity = async (inputQtyValue, inventoryItemId) => {
    setError(null);
    await axios.post(
      `${config.API_URL}/api/v1/shopify/products/product/quantity`,
      {
        inventory_item_id: inventoryItemId.split("/").pop(),
        available: parseFloat(inputQtyValue || 0)
      },
      headers(token)
    );
    _updateProductsLocaly(inventoryItemId);
  };

  const exportToCSV = () => {
    try {
      const tableId = "#deltaQuantities";
      const filename = `shopify to praxis quantities on ${inputValue}.csv`;
      ExportTableToCSV(tableId, filename);
    } catch (err) {
      setError(
        `Error exporting data to CSV file. Please try again. (Error: ${err})`
      );
    }
  };

  const handleBulkLimitChange = event => {
    const newLimit = parseInt(
      event.target.value || limitBulkProductDetailsCalls,
      10
    );
    setLimitBulkChunkSize(
      newLimit > _limitBulkProductDetailsCalls
        ? limitBulkProductDetailsCalls
        : newLimit
    );
  };

  const isDateValid = () => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return inputValue.length && datePattern.test(inputValue);
  };

  const getNewProducts = formattedProducts => {
    const nonExistingProducts = formattedProducts.filter(
      p => p.status === "New" && p.praxisQuantity > 0
    );
    const trimmedNewProducts = nonExistingProducts.map(product => ({
      ...product,
      praxisBarcode: product.praxisBarcode.slice(0, -2)
    }));
    const uniqueBarcodes = new Map();
    trimmedNewProducts.forEach(product => {
      if (!uniqueBarcodes.has(product.praxisBarcode)) {
        uniqueBarcodes.set(product.praxisBarcode, { ...product });
      }
    });
    return Array.from(uniqueBarcodes.values());
  };

  const renderProductList = () => {
    const formattedProducts = createProductData();
    if (formattedProducts && formattedProducts.length > 0) {
      const exsistingRequireUpdateQtyProducts = formattedProducts.filter(
        p => !!p.delta
      );

      const newProducts = getNewProducts(formattedProducts);

      const exsistingProducts = formattedProducts.filter(
        p => p.delta >= 0 || p.delta < 0
      );

      const renderedProducts =
        filterBy === "new"
          ? newProducts
          : filterBy === "require_update"
          ? exsistingRequireUpdateQtyProducts
          : exsistingProducts;

      return (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 style={{ margin: 0 }}>{renderListTitle()}</h2>
            {renderPagination()}
          </div>
          <table id="deltaQuantities">
            <thead>
              <tr>
                <th>Barcode</th>
                <th>SKU</th>
                <th>Product ID</th>
                <th>Title</th>
                <th>Praxis Product Title</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Praxis Quantity</th>
                <th>Delta</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {renderedProducts.map((product, index) => {
                return (
                  <tr key={`product-delta-quantities-${index}`}>
                    <td>{product.praxisBarcode}</td>
                    <td>{product.sku}</td>
                    <td>{product.productId}</td>
                    <td>{product.title}</td>
                    <td>{product.praxisProductTitle}</td>
                    <td>{product.price}</td>
                    <td>{parseFloat(product.quantity || 0)}</td>
                    <td>{parseFloat(product.praxisQuantity || 0)}</td>
                    <td
                      className={`${
                        product.delta > 0
                          ? "highlight_green"
                          : product.delta < 0
                          ? "highlight_red"
                          : ""
                      }`}
                    >
                      {product.delta >= 0 || product.delta < 0 ? (
                        product.delta
                      ) : (
                        <span className="highlight_orange">New</span>
                      )}
                    </td>
                    {product.status === "Ok" && (
                      <td className="highlight_green action">
                        No Action Required
                      </td>
                    )}
                    {product.status === "Differ" && (
                      <td className="action">
                        <button
                          onClick={() => {
                            updateProductQuantity(
                              product.praxisQuantity,
                              product.inventoryId
                            );
                          }}
                          disabled={
                            !product.delta || product.delta === 0 || loading
                          }
                        >
                          Adjust Quantity
                        </button>
                      </td>
                    )}
                    {product.status === "New" && (
                      <td className="highlight_blue">
                        Must be shooted and added to Shopify
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    } else return <></>;
  };

  const renderListTitle = () => {
    const listData = createProductData();
    const DifferProducts = listData.filter(p => !!p.delta).length;
    const CorrectProducts = listData.filter(p => p.delta === 0).length;
    const full = selectedArray.length ? selectedArray.length : 0;
    const NewProducts = getNewProducts(listData).length;
    const NewVariants =
      products.length !== 0 && listData?.length !== 0
        ? full - (DifferProducts + CorrectProducts)
        : 0;
    const needAdjustment = DifferProducts > 0 && (
      <span style={{ fontSize: "20px" }} className="highlight_blue">
        {` ${DifferProducts} Adjust Qty Required`}
        {CorrectProducts > 0 && ", "}
      </span>
    );
    const noAdjustment = CorrectProducts > 0 && (
      <span style={{ fontSize: "20px" }} className="highlight_green">
        {` ${CorrectProducts} No Action Required`}
        {NewProducts > 0 && ", "}
      </span>
    );
    const differProducts = NewProducts > 0 && (
      <span style={{ fontSize: "20px" }} className="highlight_orange">
        {` (${NewVariants} New variants for ${NewProducts} New products)`}
      </span>
    );
    return (
      <>
        <span style={{ fontSize: "20px" }}>{`Chunk ${
          selectedButtonIndex + 1
        } => ${full} Product(s):`}</span>
        {needAdjustment} {noAdjustment} {differProducts}
      </>
    );
  };

  const renderPagination = () => {
    const listData = createProductData();
    const DifferProducts = listData.filter(p => !!p.delta).length;
    return listData && listData.length ? (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <button
          style={{ marginLeft: "8px", fontSize: "12px", padding: "10px 4px" }}
          className="highlight_blue"
          disabled={loading}
          onClick={exportToCSV}
        >
          Export to CSV
        </button>
        <button onClick={bulkUpdate} disabled={DifferProducts === 0 || loading}>
          {loading
            ? "Loding..."
            : `Bulk Update (${Math.ceil(
                DifferProducts / limitBulkUpdateCalls
              )} bulks to send)`}
        </button>
      </div>
    ) : (
      <></>
    );
  };

  const renderChunksButtons = () => {
    return chunkedArrays.map((_, index) => (
      <button
        key={`button_${index}`}
        disabled={selectedButtonIndex === index || loading}
        className={`${
          selectedButtonIndex === index && !loading && "active_button"
        }`}
        style={{ color: selectedHighButtonIndex === index ? "blue" : "black" }}
        onClick={() => {
          setSelectedArray(chunkedArrays[index]);
          setSelectedButtonIndex(index);
        }}
      >
        {`${
          selectedHighButtonIndex === index
            ? "Update Bulk done ... "
            : `Chunk ${index + 1} (${chunkedArrays[index].length} found)`
        }`}
      </button>
    ));
  };

  const renderFilterButtons = () => {
    return (
      <>
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginBottom: "8px"
          }}
        >
          <label>Show only : </label>

          <input
            type="checkbox"
            id="checkbox"
            checked={filterBy === "existing"}
            onChange={() => {
              setFilterBy("existing");
              if (products?.length > 0) {
                renderProductList();
              }
            }}
          />
          <label className="highlight_green" htmlFor="checkbox">
            Existing
          </label>

          <input
            type="checkbox"
            id="checkbox"
            checked={filterBy === "require_update"}
            onChange={() => {
              setFilterBy("require_update");
              if (products?.length > 0) {
                renderProductList();
              }
            }}
          />
          <label className="highlight_blue" htmlFor="checkbox">
            Require update
          </label>

          <input
            type="checkbox"
            id="checkbox"
            checked={filterBy === "new"}
            onChange={() => {
              setFilterBy("new");
              if (products?.length > 0) {
                renderProductList();
              }
            }}
          />
          <label className="highlight_orange" htmlFor="checkbox">
            New
          </label>
        </div>
      </>
    );
  };

  // main render function

  return (
    <div>
      <h2>Products Quantities Deltas + New Products</h2>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            <div>
              <label>
                Date (YYYY-MM-DD):
                <input
                  type="text"
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                />
              </label>
              <button
                className={isDateValid() ? "highlight_blue" : ""}
                disabled={!isDateValid() || loading}
                onClick={fetchShopifyProducts}
              >
                {`Fetch latest Deltas ${
                  isDateValid() ? `on ${inputValue}` : ""
                }`}
              </button>
            </div>
            <div
              style={{ margin: "8px 0", display: "flex", alignItems: "center" }}
            >
              <FileUpload
                label={"Upload praxis data file"}
                _disabled={loading}
                _selectedFile={_selectedFile}
              />
              <button
                className={uploadedData?.length > 1 ? "highlight_blue" : ""}
                disabled={loading || uploadedData?.length === 0}
                onClick={fetchShopifyProductsWithUploadedData}
              >
                Fetch Deltas Between Shopify and Uploaded data
              </button>
            </div>
          </div>
          <div>
            {renderFilterButtons()}
            <div>
              <label>
                Chunk Size (
                {`${limitBulkProductDetailsCalls} ~ ${_limitBulkProductDetailsCalls}`}{" "}
                max):
                <input
                  style={{ width: "50px" }}
                  type="text"
                  value={limitBulkChunkSize}
                  onChange={handleBulkLimitChange}
                />
              </label>
            </div>
          </div>
        </div>
      </>
      {loading && (
        <div style={{ margin: "16px" }} className="loading">
          {callCounter > 0 && (
            <>
              <div>{`${
                callCounter - 1 > 0 ? `${callCounter - 1} Chunk(s) cached` : ""
              } ~ ${
                callCounter * (Math.floor(Math.random() * (1001 - 900)) + 900)
              } variants Fetched ...`}</div>
            </>
          )}
          <div dangerouslySetInnerHTML={{ __html: loadingMsg }} />
          <div>{`${
            callCounter || loadingMsg
              ? "Please wait ..."
              : "Loading please wait ..."
          }`}</div>
        </div>
      )}
      {error && <p className="error">Error: {error}</p>}
      <div className="sync_panel">
        <div className="sync_left_panel">
          {chunkedArrays?.length > 0 && renderChunksButtons()}
        </div>
        <div className="sync_right_panel">{renderProductList()}</div>
      </div>
    </div>
  );
};

export default SyncQtyStockToFront;
