/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import config from "./config";
import { connectedUserToken, signOut } from "./Utils/firebase";

import "./App.css";

import SignInComponent from "./Containers/signin";
import AllProducts from "./Containers/allProduts";
import SearchBySku from "./Containers/searchBySku";
import UpdatedPrices from "./Containers/updatedPrices";
import UpdatedAvailibilities from "./Containers/updatedAvailibilities";
import SyncQtyStockToFront from "./Containers/syncQtyStockToFront";
import SyncPricesStockToFront from "./Containers/syncPricesStockToFront";
import StockProducts from "./Containers/stockProducts";

const TabManager = ({ tabs, token }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeSubTabIndex, setActiveSubTabIndex] = useState(0);

  const handleTabClick = index => {
    setActiveTabIndex(index);
    setActiveSubTabIndex(0); // Reset subtab index to show the first subtab by default
  };

  const handleSubTabClick = index => {
    setActiveSubTabIndex(index);
  };

  const ActiveSubTabComponent =
    tabs[activeTabIndex].subTabs[activeSubTabIndex].component;

  return (
    <div>
      <div className="tab-list">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab ${index === activeTabIndex ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="subtab-list">
        {tabs[activeTabIndex].subTabs.map((subTab, index) => (
          <button
            key={index}
            className={`subtab ${index === activeSubTabIndex ? "active" : ""}`}
            onClick={() => handleSubTabClick(index)}
          >
            {subTab.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {ActiveSubTabComponent && <ActiveSubTabComponent token={token} />}
      </div>
    </div>
  );
};

const ServerStatus = ({ token }) => {
  const [serverStatus, setServerStatus] = useState(false);

  useEffect(() => {
    checkApiServerStatus();
  }, []);

  const checkApiServerStatus = () => {
    axios
      .get(`${config.API_URL}`)
      .then(_res => {
        setServerStatus(true);
      })
      .catch(error => {
        setServerStatus("Offline / Error:" + error.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center"
      }}
    >
      <div
        className={`${
          serverStatus === true ? "highlight_green" : "highlight_red"
        }`}
      >
        {serverStatus === true ? "Server Online" : serverStatus}
      </div>
      {!!token && (
        <div
          style={{
            marginLeft: "4px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center"
          }}
          className="highlight_green"
        >
          <span>and User Connected</span>
          <button onClick={signOut}>Logout</button>
        </div>
      )}
    </div>
  );
};

const Main = () => {
  const [token, setToken] = useState(null);
  const [showLoginUi, setShowLoginUi] = useState(false);

  const _connectedUserToken = async () => {
    const token = await connectedUserToken();
    setToken(token);
    setShowLoginUi(!token);
  };

  useEffect(() => {
    _connectedUserToken();
  }, []);

  useEffect(() => {
    const checkLoginStatus = () => {
      const userLoggedIn = Cookies.get("userLoggedIn");
      if (token) {
        if (!userLoggedIn) {
          signOut();
        }
      }
    };
    checkLoginStatus();
    const intervalId = setInterval(checkLoginStatus, 15 * 60 * 1000); // 15 minutes * 60 seconds * 1000
    if (!token) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [token]);

  useEffect(() => {
    const handleUnload = () => {
      Cookies.remove("userLoggedIn");
      Cookies.remove("userExpireSession");
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const tabs = [
    {
      title: "Shopify",
      subTabs: [
        { title: "Products", component: AllProducts },
        {
          title: "Search by SKU or Barcode",
          component: SearchBySku
        }
      ]
    },
    {
      title: "Praxis",
      subTabs: [
        { title: "Updated Prices", component: UpdatedPrices },
        {
          title: "Updated Quantities (Availabilities)",
          component: UpdatedAvailibilities
        },
        { title: "Products", component: StockProducts }
      ]
    },
    {
      title: "Sync Data (Praxis => Shopify)",
      subTabs: [
        {
          title: "Sync Quantity Stock + New Products",
          component: SyncQtyStockToFront
        },
        {
          title: "Sync Price Stock",
          component: SyncPricesStockToFront
        }
      ]
    },
    {
      title: "Reports & Tools",
      subTabs: [
        {
          title: "Manequin"
          // component: manequinReport,
          // props: { token }
        }
      ]
    }
  ];

  return (
    <div className="App">
      <ServerStatus token={token} />
      <hr></hr>
      {showLoginUi && <SignInComponent _callBack={_connectedUserToken} />}
      {!!token && <TabManager tabs={tabs} token={token} />}
    </div>
  );
};

function App() {
  return <Main />;
}

export default App;
