import React, { useState } from "react";
import Cookies from "js-cookie";
import { signIn } from "../Utils/firebase";

const SignInComponent = ({ _callBack }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const _signIn = async () => {
    const _resp = await signIn(email, password);
    if (_resp.error) {
      setError(error);
    } else {
      const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds
      Cookies.set("userLoggedIn", "true", {
        expires: inOneHour,
        sameSite: "lax",
        secure: true,
        path: "/"
      });
      _callBack();
    }
  };

  return (
    <div style={styles.container}>
      <label style={styles.label}>Email:</label>
      <input
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        style={styles.input}
      />

      <label style={styles.label}>Password:</label>
      <input
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        style={styles.input}
      />

      <button onClick={_signIn} style={styles.button}>
        Sign In
      </button>
      {error && <p style={styles.error}>Error: {error}</p>}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "300px",
    margin: "50px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
  },
  label: {
    display: "block",
    margin: "10px 0",
    fontWeight: "bold"
  },
  input: {
    width: "100%",
    padding: "8px",
    margin: "5px 0 15px 0",
    boxSizing: "border-box"
  },
  button: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px 15px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer"
  },
  error: {
    color: "red",
    marginTop: "10px"
  }
};

export default SignInComponent;
