import Cookies from "js-cookie";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const connectedUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        user
          .getIdToken()
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            unsubscribe();
          });
      } else {
        resolve(null);
      }
    });
  });
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (userCredential?.user) {
      return { user: userCredential.user, error: null };
    }
  } catch (error) {
    return { user: null, error: error };
  }
};

export const signOut = async () => {
  try {
    await auth.signOut();
    Cookies.remove("userLoggedIn");
    window.location.reload();
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

export const headers = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

export const headersWithBlob = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: "blob"
  };
};
