import React, { useState } from "react";
import axios from "axios";
import { ExportTableToCSV } from "export-table-to-csv";
import { headers } from "../Utils/firebase";
import { _handleUpload } from "../Utils/upload";
import FileUpload from "./fileUpload";
import config from "../config";

const StockProducts = ({ token }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${config.API_URL}/api/v1/praxis/products`,
        headers(token)
      );
      if (response.status !== 200) {
        setLoading(false);
        setError(`Error fetching Praxis products. Please try again`);
        return null;
      }
      return response;
    } catch (err) {
      setLoading(false);
      setError(
        `Error fetching Praxis products. Please try again. (Error: ${err})`
      );
    }
  };

  const getAllProducts = async () => {
    setProducts([]);
    setLoading(true);
    setError(null);
    const allProducts = await fetchData();
    if (!allProducts) return;
    setProducts(allProducts.data);
    setLoading(false);
  };

  const renderProductList = () => {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <h2>
            Praxis Product List
            {` (${products?.length}) records`}
          </h2>
          <button
            style={{ marginLeft: "8px", alignItems: "baseline" }}
            className="highlight_blue"
            disabled={loading}
            onClick={exportToCSV}
          >
            Export to CSV
          </button>
        </div>
        <table id="stockProducts">
          <thead>
            <tr>
              <th>Barcode</th>
              <th>Product Title</th>
              <th>Quantity</th>
              <th>Price Fbc</th>
              <th>Price Sbc</th>
              <th>Reduced Price Fbc</th>
              <th>Reduced Price Sbc</th>
            </tr>
          </thead>
          <tbody>{_renderProductList()}</tbody>
        </table>
      </div>
    );
  };

  const exportToCSV = () => {
    try {
      const tableId = "#updatedQty";
      const filename = `praxis stock.csv`;
      ExportTableToCSV(tableId, filename);
    } catch (err) {
      setError(
        `Error exporting data to CSV file. Please try again. (Error: ${err})`
      );
    }
  };

  const _selectedFile = ({ file, error }) => {
    if (file) {
      setError(false);
      setLoading(true);
      _handleUpload(file, setError, setLoading, setProducts);
    } else {
      setError(error);
    }
  };

  const _renderProductList = () => {
    if (!!products?.length) {
      const edges = products;
      const formattedProducts = [];
      // eslint-disable-next-line array-callback-return
      edges.map(edge => {
        const product = {
          barcode: edge.barcode,
          name: edge.name,
          quantity: edge.availableQty,
          priceFbc: edge.defaultPriceFbc,
          priceSbc: edge.defaultPriceSbc,
          reducedPriceFbc: edge.reducedPriceFbc,
          reducedPriceSbc: edge.reducedPriceSbc
        };
        formattedProducts.push(product);
      });
      return formattedProducts.map((product, index) => (
        <tr key={`product-${index}`}>
          <td>{product.barcode}</td>
          <td>{product.name}</td>
          <td>{product.quantity}</td>
          <td>{product.priceFbc}</td>
          <td>{product.priceSbc}</td>
          <td>{product.reducedPriceFbc}</td>
          <td>{product.reducedPriceSbc}</td>
        </tr>
      ));
    } else if (!products?.length) {
      return (
        <tr>
          <td style={{ fontSize: "18px" }} className="highlight_red">
            Not Found ...
          </td>
        </tr>
      );
    } else return <></>;
  };

  return (
    <div>
      <h2>Products</h2>
      <h4>Upload a praxis data file of type .xlsx</h4>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FileUpload
          label={"Upload praxis data file"}
          _disabled={loading}
          _selectedFile={_selectedFile}
        />
        <button style={{ display: "none" }} disabled onClick={getAllProducts}>
          Fetch All Praxis Products
        </button>
      </div>
      {loading && (
        <p className="loading">{`Fetching from praxis, please wait ...`}</p>
      )}
      {error && <p className="error">Error: {error}</p>}
      {!!products?.length && renderProductList()}
    </div>
  );
};

export default StockProducts;
