import React, { useEffect, useState } from "react";
import axios from "axios";
import { ExportTableToCSV } from "export-table-to-csv";
import { headers, headersWithBlob } from "../Utils/firebase";
import config from "../config";

const FitlerForm = ({
  _allProducts,
  _setFilterdProducts,
  _clearHighlightedFilter
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const getProductsByBarcode = () => {
    _clearHighlightedFilter();
    setSelectedButtonIndex(1);
    _setFilterdProducts(
      _allProducts.filter(product => product.barcode?.includes(inputValue))
    );
  };

  const getProductsBySku = () => {
    _clearHighlightedFilter();
    setSelectedButtonIndex(2);
    _setFilterdProducts(
      _allProducts.filter(product => product.sku?.includes(inputValue))
    );
  };

  const getProductsByName = () => {
    _clearHighlightedFilter();
    setSelectedButtonIndex(3);
    _setFilterdProducts(
      _allProducts.filter(product => product.displayName?.includes(inputValue))
    );
  };

  const getProductsByQuantity = () => {
    _clearHighlightedFilter();
    setSelectedButtonIndex(4);
    _setFilterdProducts(
      _allProducts.filter(
        product =>
          product.inventoryQuantity &&
          parseFloat(product.inventoryQuantity) === parseFloat(inputValue)
      )
    );
  };

  const getProductsByPrice = () => {
    _clearHighlightedFilter();
    setSelectedButtonIndex(5);
    _setFilterdProducts(
      _allProducts.filter(
        product =>
          product.price && parseFloat(product.price) === parseFloat(inputValue)
      )
    );
  };

  const reset = () => {
    setInputValue("");
    setSelectedButtonIndex(null);
    _clearHighlightedFilter();
    _setFilterdProducts(_allProducts);
  };

  return (
    <div>
      <label>
        Search for:
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </label>
      <button
        className={`${selectedButtonIndex === 1 && "highlight_blue"}`}
        onClick={getProductsByBarcode}
      >
        in Barcode
      </button>
      <button
        className={`${selectedButtonIndex === 2 && "highlight_blue"}`}
        onClick={getProductsBySku}
      >
        in SKU
      </button>
      <button
        className={`${selectedButtonIndex === 3 && "highlight_blue"}`}
        onClick={getProductsByName}
      >
        in Name
      </button>
      <button
        className={`${selectedButtonIndex === 4 && "highlight_blue"}`}
        onClick={getProductsByQuantity}
      >
        in Quantity
      </button>
      <button
        className={`${selectedButtonIndex === 5 && "highlight_blue"}`}
        onClick={getProductsByPrice}
      >
        in Price ($)
      </button>
      <button style={{ color: "coral" }} onClick={reset}>
        Clear Filter
      </button>
    </div>
  );
};

const ViewChanger = ({ _handleToggle }) => {
  const [_showView, _setShowView] = useState(4);

  useEffect(() => {
    _handleToggle(_showView);
  }, [_handleToggle, _showView]);

  const handleToggle = step => {
    _setShowView(step);
  };

  return (
    <>
      <label>
        Show All with ID(s)
        <input
          type="checkbox"
          checked={_showView === 1}
          onChange={() => {
            handleToggle(1);
          }}
        />
      </label>

      <label
        style={{
          marginLeft: "20px"
        }}
      >
        Show All
        <input
          type="checkbox"
          checked={_showView === 4}
          onChange={() => {
            handleToggle(4);
          }}
        />
      </label>

      <label
        style={{
          marginLeft: "20px"
        }}
      >
        Show Only Barcode
        <input
          type="checkbox"
          checked={_showView === 2}
          onChange={() => {
            handleToggle(2);
          }}
        />
      </label>

      <label
        style={{
          margin: "0 20px"
        }}
      >
        Show Only Sku
        <input
          type="checkbox"
          checked={_showView === 3}
          onChange={() => {
            handleToggle(3);
          }}
        />
      </label>
    </>
  );
};

const AllProducts = ({ token }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [filterdProducts, setFilterdProducts] = useState([]);
  const [callCounter, setCallCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showView, setShowView] = useState(4);
  const [error, setError] = useState(null);
  const [selectedMismatchButton, setSelectedMismatchButton] = useState(false);

  const getProductsByCursor = async (first, after) => {
    try {
      setCallCounter(prevCounter => prevCounter + 1);
      const response = await axios.post(
        `${config.API_URL}/api/v1/shopify/products`,
        {
          first,
          after
        },
        headers(token)
      );
      return response.data.data;
    } catch (error) {
      setError("Get products by cursor fail");
      setLoading(false);
    }
  };

  const getAllProducts = async (afterCursor = null) => {
    try {
      const { products, pageInfo } = await getProductsByCursor(
        250,
        afterCursor
      );
      if (pageInfo && pageInfo.hasNextPage) {
        const remainingProducts = await getAllProducts(pageInfo.endCursor);
        return [...products, ...remainingProducts];
      } else if (products?.data?.length === 0) {
        setError("No products found");
        return [];
      } else {
        return products;
      }
    } catch (error) {
      setError("Get all products fail");
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      setAllProducts([]);
      setFilterdProducts([]);
      setCallCounter(0);
      setSelectedMismatchButton(false);
      const products = await getAllProducts();
      setShowView(4);
      setAllProducts(products);
      setFilterdProducts(products);
      setLoading(false);
    } catch (error) {
      setError("Fetching products fail");
      setLoading(false);
    }
  };

  const getAllProductsImages = () => {
    axios
      .get(
        `${config.API_URL}/download-products-images-zip`,
        headersWithBlob(token)
      )
      .then(response => {
        // Create a URL for the blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temp <a /> tag to trigger download
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.zip"); // Specify the download name of the file.
        document.body.appendChild(fileLink);

        fileLink.click(); // Simulate click on the link to download.

        // Clean up
        fileLink.parentNode.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL); // Free up the memory from the blob

        setLoading(false);
      })
      .catch(error => {
        setError("Fetching products images fail");
        setLoading(false);
      });
  };

  const downloadProductsImages = async () => {
    try {
      setLoading(true);
      setError(null);
      getAllProductsImages();
    } catch (error) {
      setError("Fetching products images fail");
      setLoading(false);
    }
  };

  const renderAll = () => {
    const products = filterdProducts;
    const id = "allProducts";
    return (
      <>
        <button
          style={{
            marginLeft: "8px",
            alignItems: "baseline",
            position: "absolute",
            right: "0",
            top: "15px"
          }}
          className="highlight_blue"
          disabled={loading}
          onClick={() => {
            exportToCSV(id);
          }}
        >
          Export to CSV
        </button>
        <table id={id}>
          <thead>
            <tr>
              <th>Barcode</th>
              <th>SKU</th>
              <th>Display Name</th>
              <th>Pruduct ID</th>
              <th>Variant ID</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {products.map((_products, index) => (
              <tr key={`products-${index}`}>
                <td>{_products.barcode || "NAN"}</td>
                <td>{_products.sku || "NAN"}</td>
                <td>{_products.displayName}</td>
                <td>{_products.productId}</td>
                <td>{_products.variantId}</td>
                <td>{_products.price}</td>
                <td>{_products.inventoryQuantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const renderWithoutIds = () => {
    const products = filterdProducts;
    const id = "allProductsWithoutIds";
    return (
      <>
        <button
          style={{
            marginLeft: "8px",
            alignItems: "baseline",
            position: "absolute",
            right: "0",
            top: "15px"
          }}
          className="highlight_blue"
          disabled={loading}
          onClick={() => {
            exportToCSV(id);
          }}
        >
          Export to CSV
        </button>
        <table id={id}>
          <thead>
            <tr>
              <th>Barcode</th>
              <th>SKU</th>
              <th>Display Name</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={`products-${index}`}>
                <td>{product.barcode || "NAN"}</td>
                <td>{product.sku || "NAN"}</td>
                <td>{product.displayName}</td>
                <td>{product.price}</td>
                <td>{product.inventoryQuantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const renderOnlyBarcode = () => {
    const products = filterdProducts;
    const id = "allProductsOnlyBarcode";
    return (
      <>
        <button
          style={{
            marginLeft: "8px",
            alignItems: "baseline",
            position: "absolute",
            right: "0",
            top: "15px"
          }}
          className="highlight_blue"
          disabled={loading}
          onClick={() => {
            exportToCSV(id);
          }}
        >
          Export to CSV
        </button>
        <table id={id}>
          <thead>
            <tr>
              <th>Barcode</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={`products-barcode-${index}`}>
                <td>{product.barcode || "NAN"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const renderOnlySku = () => {
    const products = filterdProducts;
    const id = "allProductsOnlySku";
    return (
      <>
        <button
          style={{
            marginLeft: "8px",
            alignItems: "baseline",
            position: "absolute",
            right: "0",
            top: "15px"
          }}
          className="highlight_blue"
          disabled={loading}
          onClick={() => {
            exportToCSV(id);
          }}
        >
          Export to CSV
        </button>
        <table id={id}>
          <thead>
            <tr>
              <th>SKU</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={`products-sku-${index}`}>
                <td>{product.sku || "NAN"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const exportToCSV = id => {
    try {
      const tableId = `#${id}`;
      const filename = `shopify product list ${id}.csv`;
      ExportTableToCSV(tableId, filename);
    } catch (err) {
      setError(
        `Error exporting data to CSV file. Please try again. (Error: ${err})`
      );
    }
  };

  const fetchMismatchesBarcodeSKU = () => {
    setSelectedMismatchButton(true);
    setFilterdProducts(
      allProducts.filter(product => product.barcode !== product.sku)
    );
  };

  return (
    <div>
      <h2>Products (Variants)</h2>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <ViewChanger
            _handleToggle={_data => {
              setShowView(_data);
            }}
          />

          <button onClick={fetchData}>Fetch All Products</button>
          {!!allProducts?.length && !loading && (
            <button
              className={`${!!selectedMismatchButton && "highlight_blue"}`}
              onClick={fetchMismatchesBarcodeSKU}
            >
              Get Mismatches between Barcode and SKU
            </button>
          )}
        </div>
        <div>
          <button onClick={downloadProductsImages}>
            Download All Products Images
          </button>
        </div>
      </div>
      {!!allProducts?.length && !loading && (
        <div style={{ marginTop: 20 }}>
          <FitlerForm
            _allProducts={allProducts}
            _clearHighlightedFilter={() => {
              setSelectedMismatchButton(false);
            }}
            _setFilterdProducts={_data => {
              setFilterdProducts(_data);
            }}
          />
        </div>
      )}

      {loading && (
        <p className="loading">
          {callCounter > 0 ? (
            <span>{`${
              callCounter - 1 > 0 ? `${callCounter - 1} Chunks cached` : ""
            } ~ ${
              callCounter * (Math.floor(Math.random() * (1001 - 900)) + 900)
            } variants Fetched ...`}</span>
          ) : (
            <span>Please Wait ...</span>
          )}
        </p>
      )}
      {error && <p className="error">Error: {error}</p>}

      <div style={{ position: "relative" }}>
        {!!allProducts?.length && (
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <h2>{`${filterdProducts.length}/${allProducts.length} products fetched`}</h2>
          </div>
        )}

        {!!filterdProducts?.length && showView === 1 && renderAll()}
        {!!filterdProducts?.length && showView === 2 && renderOnlyBarcode()}
        {!!filterdProducts?.length && showView === 3 && renderOnlySku()}
        {!!filterdProducts?.length && showView === 4 && renderWithoutIds()}
      </div>
    </div>
  );
};

export default AllProducts;
