import config from "../config";

export const _handleUpload = (
  _selectedFile,
  _setError,
  _setLoading,
  _setUploadedData
) => {
  if (_selectedFile) {
    const formData = new FormData();
    formData.append("file", _selectedFile);
    const fileExtension = _selectedFile.name.split(".").pop().toLowerCase();
    fetch(`${config.API_URL}/upload`, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          _setError(data.error);
        } else if (fileExtension === "xlsx") {
          if (
            fileExtension === "xlsx" &&
            (data[0]["col1"] !== "Barcode" ||
              data[0]["col2"] !== "Name" ||
              data[0]["col3"] !== "Available Qty." ||
              data[0]["col4"] !== "Default Price (LBP)" ||
              data[0]["col5"] !== "Default Price (USD)" ||
              data[0]["col6"] !== "Selling Price (LBP)" ||
              data[0]["col7"] !== "Selling Price (USD)")
          ) {
            _setError(
              "Invalid file format. Please upload a valid file. Columns must be in this order Barcode / Name / Available Qty. / Default Price (LBP) / Default Price (USD) / Selling Price (LBP) / Selling Price (USD)"
            );
            return;
          } else {
            const _json = data.map((item, index) => {
              return {
                barcode: item["col1"],
                name: item["col2"],
                availableQty: item["col3"],
                defaultPriceFbc: item["col4"],
                defaultPrice: item["col5"],
                reducedPriceFbc: item["col6"],
                reducedPrice: item["col7"]
              };
            });
            _setUploadedData(_json.slice(1, _json.length));
          }
        } else {
          _setUploadedData(data);
        }
      })
      .catch(_err => {
        _setError("Error uploading file");
      })
      .finally(() => {
        _setLoading(false);
      });
  } else {
    _setLoading(false);
    _setError("No file selected");
  }
};
