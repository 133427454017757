import React, { useState } from "react";
import axios from "axios";
import { ExportTableToCSV } from "export-table-to-csv";
import { headers } from "../Utils/firebase";
import config from "../config";

const UpdatedAvailibilities = ({ token }) => {
  const [inputValue, setInputValue] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiCallCount, setApiCallCount] = useState(0);

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const fetchData = async page => {
    await delay(200);
    const response = await axios.get(
      `${config.API_URL}/api/v1/praxis/products/availibilities/${inputValue}/${page}`,
      headers(token)
    );
    setApiCallCount(prevCount => prevCount + 1);
    return response;
  };

  const getUpdatedProductAvailibilities = async () => {
    setProducts([]);
    setLoading(true);
    setError(null);
    let allProducts = [];

    const initialResponse = await fetchData(1);
    if (
      initialResponse.data?.data &&
      initialResponse.data?.data?.totalPages > 0
    ) {
      allProducts = [...initialResponse.data.data.data];
      const totalPages = initialResponse.data.data.totalPages;
      const pagePromises = [];
      for (let page = 2; page <= totalPages; page++) {
        pagePromises.push(await fetchData(page));
      }
      const pageResponses = await Promise.all(pagePromises);
      pageResponses.forEach(response => {
        setApiCallCount(prevCount => prevCount + 1);
        allProducts = allProducts.concat(response.data.data.data);
      });
    }
    setProducts(allProducts);
    setApiCallCount(0);
    setLoading(false);
  };

  const renderProductList = () => {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <h2>
            Updated Product Availibilities List
            {` (${products?.length}) records`}
          </h2>
          <button
            style={{ marginLeft: "8px", alignItems: "baseline" }}
            className="highlight_blue"
            disabled={loading}
            onClick={exportToCSV}
          >
            Export to CSV
          </button>
        </div>
        <table id="updatedQty">
          <thead>
            <tr>
              <th>Barcode</th>
              <th>Product Title</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>{_renderProductList()}</tbody>
        </table>
      </div>
    );
  };

  const _renderProductList = () => {
    if (!!products?.length) {
      const edges = products;
      const formattedProducts = [];
      // eslint-disable-next-line array-callback-return
      edges.map(edge => {
        const product = {
          barcode: edge.barcode,
          name: edge.name,
          quantity: edge.availableQty
        };
        formattedProducts.push(product);
      });
      return formattedProducts.map((product, index) => (
        <tr key={`product-updated-availibilities-${index}`}>
          <td>{product.barcode}</td>
          <td>{product.name}</td>
          <td>{product.quantity}</td>
        </tr>
      ));
    } else if (!products?.length) {
      return (
        <tr>
          <td style={{ fontSize: "18px" }} className="highlight_red">
            Not Found ...
          </td>
        </tr>
      );
    } else return <></>;
  };

  const isDateValid = () => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return inputValue.length && datePattern.test(inputValue);
  };

  const exportToCSV = () => {
    try {
      const tableId = "#updatedQty";
      const filename = `praxis updated quantities on ${inputValue}.csv`;
      ExportTableToCSV(tableId, filename);
    } catch (err) {
      setError(
        `Error exporting data to CSV file. Please try again. (Error: ${err})`
      );
    }
  };

  return (
    <div>
      <h2>Updated Availibilities</h2>
      <label>
        Date (YYYY-MM-DD):
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </label>
      <button
        disabled={loading || !isDateValid(inputValue)}
        onClick={getUpdatedProductAvailibilities}
      >
        Fetch Latest Updates from Praxis
        {`${isDateValid(inputValue) ? ` on ${inputValue}` : ""}`}
      </button>
      {loading && (
        <p className="loading">
          {`${
            apiCallCount > 0
              ? `${apiCallCount} Chunks cached ~ ${
                  apiCallCount * 500
                } parxis variants fetched, please wait ...`
              : "Fetching from praxis, please wait ..."
          }`}
        </p>
      )}
      {error && <p className="error">Error: {error}</p>}
      {!!products?.length && renderProductList()}
    </div>
  );
};

export default UpdatedAvailibilities;
