import React, { useState } from "react";
import axios from "axios";
import { headers } from "../Utils/firebase";
import config from "../config";

const SearchBySku = ({ token }) => {
  const [inputValue, setInputValue] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatedDone, setUpdatedDone] = useState(false);
  const [error, setError] = useState(null);
  const [editedQuantities, setEditedQuantities] = useState({});

  const handleQuantityChange = (inventoryId, newQuantity) => {
    setEditedQuantities(prev => ({ ...prev, [inventoryId]: newQuantity }));
  };

  const getProductBySku = async () => {
    try {
      setLoading(true);
      setError(null);
      const res = await axios.get(
        `${config.API_URL}/api/v1/shopify/products/sku/${inputValue}`,
        headers(token)
      );
      setProducts(res.data);
      setLoading(false);
    } catch (error) {
      setError("Fetching product details");
      setLoading(false);
    }
  };

  const updateProductQuantity = async inventory_item_id => {
    try {
      setLoading(true);
      setError(null);
      setUpdatedDone(false);
      const newQuantity = editedQuantities[inventory_item_id];
      await axios.post(
        `${config.API_URL}/api/v1/shopify/products/product/quantity`,
        {
          inventory_item_id: inventory_item_id.split("/").pop(),
          available: newQuantity
        },
        headers(token)
      );
      setTimeout(() => {
        getProductBySku();
        setUpdatedDone(true);
      }, 1000);
    } catch (error) {
      setError("Update product quantity fail");
      setLoading(false);
    }
  };
  const renderProductList = () => {
    return (
      products?.data?.data?.productVariants?.edges && (
        <>
          <h2>Product {`SKU: ${inputValue}`}</h2>
          <table>
            <thead>
              <tr>
                <th>Variant ID</th>
                <th>Product ID</th>
                <th>Title</th>
                <th>Product Title</th>
                <th>Price</th>
                <th>Inventory ID</th>
                <th>Quantity</th>
                <th>Sku</th>
                <th>Barcode</th>
                <th>Update Quantity</th>
              </tr>
            </thead>
            <tbody>{_renderProductList()}</tbody>
          </table>
        </>
      )
    );
  };

  const _renderProductList = () => {
    if (products?.data?.data?.productVariants?.edges?.length > 0) {
      const edges = products.data.data.productVariants.edges;
      const formattedProducts = [];
      // eslint-disable-next-line array-callback-return
      edges.map(edge => {
        const product = {
          id: edge.node.id.split("/").pop(),
          productId: edge.node.product.id.split("/").pop(),
          title: edge.node.title,
          productTitle: edge.node.product.title,
          price: edge.node.price,
          inventoryId: edge.node.inventoryItem.id.split("/").pop(),
          quantity: edge.node.inventoryQuantity,
          sku: edge.node.sku,
          barcode: edge.node.barcode
        };
        formattedProducts.push(product);
      });
      return formattedProducts.map(product => (
        <tr key={`product-${product.id}`}>
          <td>{product.id}</td>
          <td>{product.productId}</td>
          <td>{product.title}</td>
          <td>{product.productTitle}</td>
          <td>{product.price}</td>
          <td>{product.inventoryId}</td>
          <td>{product.quantity}</td>
          <td>{product.sku}</td>
          <td>{product.barcode}</td>
          <td className="action">
            <label>
              <input
                type="text"
                value={
                  editedQuantities[product.inventoryId] !== undefined
                    ? editedQuantities[product.inventoryId]
                    : product.quantity
                }
                onChange={e =>
                  handleQuantityChange(product.inventoryId, e.target.value)
                }
              />
            </label>
            <button onClick={() => updateProductQuantity(product.inventoryId)}>
              Update Qty
            </button>
          </td>
        </tr>
      ));
    } else if (products?.data?.data?.productVariants?.edges?.length === 0) {
      return (
        <tr>
          <td style={{ fontSize: "18px" }} className="highlight_red">
            Not Found ...
          </td>
        </tr>
      );
    } else <></>;
  };

  return (
    <div>
      <h2>Search by SKU or Barcode</h2>
      <label>
        Bracode or SKU:
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </label>
      <button onClick={getProductBySku}>Search by SKU</button>

      {loading && <p className="loading">Fetching product please wait...</p>}
      {updatedDone && (
        <p className="success">Quantity updated successfully in Shopify</p>
      )}
      {error && <p className="error">Error: {error}</p>}

      <div>{renderProductList()}</div>
    </div>
  );
};

export default SearchBySku;
